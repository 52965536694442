"use strict";

// Load Montserrat typeface
require('typeface-montserrat')
import './main.scss'

import { Tab } from 'bootstrap';

import Swiper, { Mousewheel, Scrollbar, Pagination, EffectCoverflow } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/swiper-bundle.css';

import LazyLoad from "vanilla-lazyload";

import Utils from "./Utils";

const $sidebarTabContent = document.querySelector('#SidebarTabContent');
const $panelFabric = document.querySelector('.panel-fabric');

// configure Swiper to use modules
Swiper.use([Mousewheel, Scrollbar, Pagination, EffectCoverflow]);

const UI = {

    // ______________________________________________________________________

    init() {
        this.swipes = [];

        this.viewport();
        this.lazyload();
        this.sidebar();
        this.swipe();
        this.tab();
    },

    // ______________________________________________________________________

    /**
     * LAZY LOAD
     */
    lazyload() {

        var LazyLoadInstance = new LazyLoad({
            container: $sidebarTabContent,
            unobserve_entered: true, // <- Avoid executing the function multiple times
        });


        var LazyLoadInstanceItem = new LazyLoad({
            container: $panelFabric,
            unobserve_entered: true, // <- Avoid executing the function multiple times
        });

        document.addEventListener('new_dropdown_item', function (e) {
            LazyLoadInstanceItem.update();
        });

        // Init
        LazyLoadInstance.update();
    },

    // ______________________________________________________________________

    viewport() {
        // AGGIORNA IL VIEWPORT

        let vh = window.innerHeight * 0.01;
        let vw = window.innerWidth * 0.01;

        document.documentElement.style.setProperty('--vh', `${vh}px`);
        document.documentElement.style.setProperty('--vw', `${vw}px`);

        window.addEventListener('resize', () => {
            // We execute the same script as before
            let vh = window.innerHeight * 0.01;
            let vw = window.innerWidth * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
            document.documentElement.style.setProperty('--vw', `${vw}px`);
        });
    },

    // ______________________________________________________________________

    swipe() {

        var that = this;

        // Swiper: Details
        this.swipes['details'] = new Swiper('.swiper-container.swiper-container-details', {
            freeMode: true,
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 'auto',
            direction: 'vertical',
            observer: true,
            mousewheel: true,
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            }
        });

        // Swiper: Style
        this.swipes['style'] = new Swiper('.swiper-container.swiper-container-style', {
            freeMode: true,
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 'auto',
            direction: 'vertical',
            observer: true,
            mousewheel: true,
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            }
        });

        // Swiper: Fabrics
        this.swipes['fabric'] = new Swiper('.swiper-container.swiper-container-fabric', {
            freeMode: true,
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 'auto',
            direction: 'vertical',
            observer: true,
            mousewheel: true,
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            /*
            centeredSlides: true,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 10,
                stretch: 0,
                depth: 10,
                modifier: 1,
                slideShadows: false,
            },
            */
        });
        document.querySelector('#total_price').addEventListener('click',() => {
            console.log('swipe',this.swipes['fabric']);
            this.swipes['fabric'].update();
        })
            

        // Swiper: Initials
        this.swipes['initials'] = new Swiper('.swiper-container.swiper-container-initials-position', {
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 3,
            nested: true,
            mousewheel: false,
            breakpoints: {
                768: {
                    slidesPerView: 5
                }
            },
        });

        // Swiper: Thread
        this.swipes['thread'] = new Swiper('.swiper-container.swiper-container-thread', {
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 5,
            nested: true,
            mousewheel: false,
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 6
                }
            },
        });

        // Swiper: Buttons
        this.swipes['buttons'] = new Swiper('.swiper-container.swiper-container-buttons', {
            spaceBetween: 10,
            slidesPerView: 4,
            nested: true,
            mousewheel: false,
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 6
                }
            },
        });

        // Swiper: Cuff
        this.swipes['cuff'] = new Swiper('.swiper-container.swiper-container-cuff', {
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 3,
            nested: true,
            mousewheel: false,
            breakpoints: {
                768: {
                    slidesPerView: 5
                }
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            }
        });

        // Swiper: Collar
        this.swipes['collar'] = new Swiper('.swiper-container.swiper-container-collar', {
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 3,
            nested: true,
            mousewheel: false,
            breakpoints: {
                768: {
                    slidesPerView: 5
                }
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            }
        });

        // Swiper: Fit
        this.swipes['fit'] = new Swiper('.swiper-container.swiper-container-fit', {
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 3,
            nested: true,
            mousewheel: false,
            breakpoints: {
                768: {
                    slidesPerView: 5
                }
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            }
        });

        // Swiper: Sleeves
        this.swipes['sleeves'] = new Swiper('.swiper-container.swiper-container-sleeves', {
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 3,
            nested: true,
            mousewheel: false,
            breakpoints: {
                768: {
                    slidesPerView: 5
                }
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            }
        });

        // Swiper: Holes
        this.swipes['holes'] = new Swiper('.swiper-container.swiper-container-holes', {
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 5,
            nested: true,
            mousewheel: false,
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 6
                }
            },
        });

        // Swiper: Placket
        this.swipes['placket'] = new Swiper('.swiper-container.swiper-container-placket', {
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 3,
            nested: true,
            mousewheel: false,
            breakpoints: {
                768: {
                    slidesPerView: 5
                }
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            }
        });
        
        // _____

        // Swiper: Back
        this.swipes['back'] = new Swiper('.swiper-container.swiper-container-back', {
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 3,
            nested: true,
            mousewheel: false,
            breakpoints: {
                768: {
                    slidesPerView: 5
                }
            },
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            }
        });

        // eccezioni
        let $back = Utils.querySelectorByAttribute("data-element_type", "8", "div");   
        $back.addEventListener('do_swipe_update',function(e) {
            that.swipes['back'].update();
        });

        // _____

        // Swiper: Sewing
        this.swipes['sewing'] = new Swiper('.swiper-container.swiper-container-sewing', {
            spaceBetween: 10,
            slidesPerView: 3,
            nested: true,
            mousewheel: false,
            scrollbar: {
                el: '.swiper-scrollbar',
                hide: true,
            },
            pagination: {
                el: '.swiper-pagination',
                dynamicBullets: true,
            },
            breakpoints: {
                768: {
                    slidesPerView: 5
                }
            },
        });

        // Swiper: Font
        this.swipes['font'] = new Swiper('.swiper-container.swiper-container-initials-font', {
            resistance: true,
            resistanceRatio: 0,
            spaceBetween: 10,
            slidesPerView: 3,
            nested: true,
            mousewheel: true,
            breakpoints: {
                768: {
                    slidesPerView: 5
                }
            },
        });

    },

    // ______________________________________________________________________

    /**
     * Update swiper
     */
    update() {
        for (var key in this.swipes) {
            this.swipes[key].update()
        }
    },

    // ______________________________________________________________________

    /**
     * Inizializza le tab
     */
    tab() {

        // var $tabs = document.querySelectorAll('a[data-toggle="tab"]');

        var triggerTabList = [].slice.call(document.querySelectorAll('#SidebarTab a'))
        var that = this;

        var $next = document.querySelector('#manf_next_step');
        var $cart = document.querySelector('#manf_add_to_cart');

        var tabTriggers = [];

        // ____________________________

        triggerTabList.forEach(function (triggerEl, i) {
            tabTriggers[i] = new Tab(triggerEl)
        
            triggerEl.addEventListener('click', function (e) {
                e.preventDefault()

                // mostra la tab
                tabTriggers[i].show();
            });

            triggerEl.addEventListener('shown.bs.tab', function (e) {
                // aggiorna swiper
                setTimeout(() => {that.update()},100);
                setTimeout(() => {that.update()},200);

                // quando si accede alla tab details, il pulsante avanti viene definitivamente sostituito da acquista
                if(this.getAttribute('href') == '#sidebar-details') {
                    $next.classList.remove('d-inline-block');
                    $next.classList.add('d-none');
                    $cart.classList.remove('d-none');
                    $cart.classList.add('d-inline-block');
                }
            });

        });

        // ____________________________

        // Quando si clicca su avanti, si va alla tab successiva
        $next.addEventListener('click',function(){
            
            // ottiene la tab corrente
            for (const [i, tabTrigger] of tabTriggers.entries()) {

                if(tabTrigger._element.classList.contains('active')) {
                    // attiva il successivo
                    if(tabTriggers[i+1]) {
                        tabTriggers[i+1].show();
                        break;
                    }
                }

            }


        })

    
   
    },

    // ______________________________________________________________________

    /**
     * Inizializza la sidebar
     */
    sidebar() {

        // SIDEBAR SWIPER

        var $menuButton = document.querySelector('.menu-button');
        var openMenu = function () {
            swiperSlider.slidePrev();
        };
        var swiperSlider = new Swiper('.swiper-container-sidebar', {
            hashNavigation: {
                watchState: true,
            },
            slidesPerView: 'auto',
            initialSlide: 1,
            resistance: true,
            resistanceRatio: 0,
            slideToClickedSlide: true,
            breakpoints: {
                1280: {
                    touchRatio: 0,
                    initialSlide: 0,
                    slideToClickedSlide: false,
                }
            },
            on: {
                init: function () {
                    var slider = this;

                    // Init Desktop: sidebar aperto
                    if (window.matchMedia('min-width:1280px') && (window.innerHeight < window.innerWidth)) {
                        slider.activeIndex = 0;
                        document.body.classList.add('sidebar-open');
                    }
                },
                slideChangeTransitionStart: function () {
                    var slider = this;
                    if (slider.activeIndex === 0) {
                        // Aperto
                        $menuButton.classList.add('active');

                        // required because of slideToClickedSlide
                        $menuButton.removeEventListener('click', openMenu, true);

                        let event = new CustomEvent('sidebar_opened');
                        document.dispatchEvent(event);

                        // aggiunge la classe sidebar open al body
                        document.body.classList.add('sidebar-open');
                    } else {
                        // Chiuso
                        $menuButton.classList.remove('active');

                        let event = new CustomEvent('sidebar_closed');

                        // rimuove la classe sidebar open al body
                        document.body.classList.remove('sidebar-open');
                    }
                },

                slideChangeTransitionEnd: function () {
                    var slider = this;
                    if (slider.activeIndex === 1) {
                        $menuButton.addEventListener('click', openMenu, true);
                    }
                },
            }
        });

    }

    // ______________________________________________________________________

};

export default UI;