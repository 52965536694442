"use strict";

import debounce from 'debounce';
import Item from './modules/Items/module.js';

import UI from './UI.js';
import Utils from './Utils.js';
import axios from 'axios';

// ______________________________________________________________________

// Shirt configuration object
var elements = {};
var Is = []; // Array di tutti gli Item stampati

var current_encoded_elements = '';

// ______________________________________________________________________

// Questi elements sono presenti nelle sidebar
var publishables_elements = [];

// ______________________________________________________________________

const API_CREATE_PRODUCTS = '/create/fw_products';
const API_SEARCH_TYPE = '/search/shirt_elements_type';
const API_SEARCH_ELEMENTS = '/search/shirt_elements';

// ______________________________________________________________________

var elements_type = [];
elements_type['1'] = "fabric";
elements_type['2'] = "collar";
elements_type['3'] = "cuff";
elements_type['4'] = "button";
elements_type['5'] = "initials_position";
elements_type['6'] = "sleeves";
elements_type['7'] = "placket";
elements_type['8'] = "back";
elements_type['9'] = "body";
elements_type['10'] = "body_button_thread";
elements_type['13'] = "body_button_hole";
elements_type['15'] = "body_buttons";
elements_type['17'] = "cuff_buttons";
elements_type['18'] = "holes";
elements_type['19'] = "cuff_hole";
elements_type['21'] = "thread_type";
elements_type['22'] = "thread_color";
elements_type['23'] = "cuff_button_thread";
elements_type['24'] = "collar_button_thread";
elements_type['25'] = "collar_button_hole";
elements_type['26'] = "collar_button";
elements_type['28'] = "folded_sleeve";
elements_type['29'] = "collar_button_down";
elements_type['30'] = "collar_button_down_thread";
elements_type['31'] = "collar_button_down_hole";
elements_type['32'] = "initials_font";
elements_type['34'] = "label";
elements_type['35'] = "fit";

// ______________________________________________________________________

// CRM settings
const CRM_BASE_URL = 'https://crm.manforti.it/';
const Bearer = 'b8959b280f18503bc85902027d1a9dc7';

// ______________________________________________________________________

// Original
//const IMAGE_URL = 'https://www.manforti.com/crm_uploads/uploads/';
const IMAGE_URL = CRM_BASE_URL + '/imgn/1/400/400/uploads/';

// ______________________________________________________________________

var data = [];
var total_price = 0;

// ______________________________________________________________________

const $ElementsCollection = document.querySelectorAll('.manf_elements');
const $render_target = document.querySelector('.custom_shirt.shirt_body');
const $createProduct = document.querySelector('#create_product');
const $addToCart = document.querySelector('#manf_add_to_cart');
const $initials = document.querySelector('#initials_input')
const $total_price = document.querySelector('#total_price');

// ______________________________________________________________________

/**
 * Prepara la connessione al CRM
 */
const CRM_instance = axios.create({
    baseURL: CRM_BASE_URL + 'rest/v1',
    timeout: 5000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        'Authorization': 'Bearer ' + Bearer
    }
});

// ______________________________________________________________________

/**
 * Utilizza i campi manf_single_element presenti nell'html per determinare quali elementi utilizzare nella configurazione
 */
const update_publishables_elements = function () {

    $ElementsCollection.forEach(($Elements_s) => {

        var $Elements = $Elements_s.querySelectorAll('.manf_single_element');
        $Elements.forEach(($Element) => {

            // Aggiorna publishables_elements
            var element_type = $Element.getAttribute('data-element_type');
            publishables_elements.push(element_type);

        });
    });

}

// ______________________________________________________________________

/**
 * Aggiunge il pulsante "Crea il prodotto"
 */
document.addEventListener('keydown', function (e) {
    if (e.ctrlKey && e.key == "l") {
        $createProduct.classList.add('show');
    }
});

// ______________________________________________________________________

/**
 * Salva il prodotto corrente come nuovo prodotto nello shop
 */
$createProduct.addEventListener('click', function () {

    let element_params = {
        fw_products_name: "Camicia su misura personalizzabile",
        fw_products_description: "Camicia pesonalizzabile nel tessuto, colletto, bottini asole e tanto altro. Personalizza la tua nuova camicia su misura per renderla unica con le tue iniziali. Le nostre camicie sono prodotte con passione in italia!",
        fw_products_sell_price: total_price,
        fw_products_quantity: "500",
        fw_products_extra: JSON.stringify(elements),
        fw_products_categories: "10",
        fw_products_sku: Math.floor(Date.now() / 1000),
        fw_products_main_image_url: $render_target.getAttribute('data-src-original')
    };

    Promise.all([post_product(element_params)]) // Promise per entrambe le chiamate
        .then(function (results) {
            //console.log(results);
        });

});

// ______________________________________________________________________

/**
 * Aggiunge il prodotto al carrello
 */
$addToCart.addEventListener('click', function (e) {

    parent.postMessage({
        configurator: {
            event: 'add_to_cart',
            image: $render_target.getAttribute('src'),
            elements: elements,
            price: total_price
        }
    }, "*")
});

// ______________________________________________________________________

/**
 * Prepara i types
 */
const init_data = function () {

    let types_params = {};
    let element_params = {
        offset: 0,
        orderby: 'shirt_elements_sell_price',
        orderdir: 'asc',
        "where[shirt_elements_enabled]": 1,
        maxdepth: 2
    };

    Promise.all([get_types(types_params), get_elements(element_params)]) // Promise per entrambe le chiamate
        .then(function (results) {

            // Raccoglie elements e types.
            // Nota: types contengono le intestazioni dal CRM
            const types = results[0].data.data;
            const elements = results[1].data.data;

            // legacy: viene utilizzato un array global "data". @todo: modulo
            data = elements;

            // inizializza i valori con quelli passati via get, oppure i defaults
            set_defaults(elements);

            // Event: API DATA OK
            let event = new CustomEvent('api_finished');
            document.dispatchEvent(event);
        });
}


// ______________________________________________________________________

/**
 * Setta i valori di default per ogni element
 * @param {} elements 
 */
const set_defaults = function (elements) {

    elements.forEach((element, index) => {
        var type = elements_type[element.shirt_elements_type];
        if (element.shirt_elements_default == 1) { // ha identificato l'elemento default

            let elementIdFromGetParam = Utils.getParam(type);

            // Se il parametro viene passato via GET, lo usa. Altrimenti usa il valore trovato come default;
            if(elementIdFromGetParam != '' || elementIdFromGetParam != undefined || elementIdFromGetParam != null) {
                select_element(type, elementIdFromGetParam);
            } else {
                select_element(type, element.shirt_elements_id);
            }

        }
    });

}

// ______________________________________________________________________

/**
 * Invia il prodotto al CRM, utilizzando la connessione al CRM
 * @param {} params 
 * @return [] types
 */
const post_product = function (params) {

    return CRM_instance({
        method: 'post',
        url: API_CREATE_PRODUCTS,
        data: Utils.objectToFormParameters(params)
    });

}

// ______________________________________________________________________

/**
 * Ottiene i types, utilizzando la connessione al CRM
 * @param {} params 
 * @return [] types
 */
const get_types = function (params) {

    return CRM_instance({
        method: 'post',
        url: API_SEARCH_TYPE,
        data: Utils.objectToFormParameters(params)
    });

}

// ______________________________________________________________________

/**
 * Ottiene gli elements, utilizzando la connessione al CRM
 * @params {} params
 * @return [] elements
 */
const get_elements = async function (params) {

    return CRM_instance({
        method: 'post',
        url: API_SEARCH_ELEMENTS,
        data: Utils.objectToFormParameters(params)
    });

}

// ______________________________________________________________________

// Inizializza i dati provenienti dalle api

init_data();

// ______________________________________________________________________

/**
 * Popola gli elementi
 * dopo api_finished
 */
document.addEventListener('api_finished', function (elem) {

    // Aggiorna gli elementi pubblicabili
    update_publishables_elements();

    // Popola gli elements con gli items
    init_elements();

    // Swiper gestisce lo scorrimento degli elementi
    UI.init()

    // Refresh shirt
    redraw();
});

// ______________________________________________________________________

/**
 * GET PARAM
 * Inizializza il form dopo api_finished
 */
document.addEventListener('api_finished', function (elem) {

    // Check get parameters
    let params = {};

    window.location.search.slice(1).split('&').forEach(elm => {
        if (elm === '') return;
        let spl = elm.split('=');
        const d = decodeURIComponent;

        elements[d(spl[0])] = (spl.length >= 2 ? d(spl[1]) : true);
    });

}, false);

// ______________________________________________________________________

/**
 * L'elemento passato come parametro viene aggiunto alla configurazione
 * @param {} type 
 * @param {*} id 
 */
const select_element = function (type, id) {

    if(Is[type] != undefined) {
        Is[type].select();
    }

    // set shirt element
    elements[type] = id;

    // Ricalcola il prezzo
    recalculate_price();

    // Gestisce casi custom di quando viene scelta un'opzione
    manageCustomSelect(type, id);
}

/**
 * Gestisce eccezioni, si attiva quando un elemento viene selezionato
 */
const manageCustomSelect = function (type, id) {

    // hard coded
    // nasconde campo di testo e gont delle iniziali quando è no initials
    if (type == 'initials_position') {
        if (id == 2208) {
            document.querySelector('.manf_single_element_initials_text .form-group').classList.add('collapse')
            document.querySelector('.manf_single_element_initials_font .swiper-container-initials-font').classList.add('collapse')
        } else {
            document.querySelector('.manf_single_element_initials_text .form-group').classList.remove('collapse')
            document.querySelector('.manf_single_element_initials_font .swiper-container-initials-font').classList.remove('collapse')
        }

        // Forza l'aggiornamento dello swiper dei font
        if (UI.swipes && UI.swipes['font'] && UI.swipes['font'] != undefined) {
            UI.swipes['font'].update();
        }
    }


}

// ______________________________________________________________________

/**
 * Ricalcola il prezzo
 */
const recalculate_price = function () {

    // reset price
    total_price = 0;

    // Cicla gli elements e per ognuno somma il price corrispondente
    Object.keys(elements).forEach(key => {
        var element_id = elements[key];

        let elementPos = data.map(function (x) {
            return x.shirt_elements_id;
        }).indexOf(element_id);

        // se l'item scelto ha un prezzo, lo aggiunge
        if (data[elementPos] != undefined && data[elementPos].shirt_elements_sell_price) {
            total_price = total_price + parseFloat(data[elementPos].shirt_elements_sell_price);
        }
    });

    // Refresh price
    $total_price.innerHTML = "€ " + total_price;
}

// ______________________________________________________________________

/**
 * Iniziali
 * Il cambio del testo è gestito con keyup + debounce
 */
$initials.onkeyup = debounce((e) => {

    // forza le iniziali a maiuscolo
    $initials.value = $initials.value.toUpperCase();

    // purga le iniziali da caratteri non in regex
    $initials.value = $initials.value.replace(/[^a-zA-Z]/gi, '');

    // In questo punto non arrivano iniziali sporche
    select_element('initials_value', $initials.value);

    redraw();
}, 500);

// ______________________________________________________________________

/**
 * Label
 * valore arbitrario
 */
select_element('label', 1453);


// ______________________________________________________________________

/**
 * Label
 * valore arbitrario
 */
select_element('label', 1453);

// ______________________________________________________________________

/**
 * Popola gli elementi consumando le API
 * @param {} type 
 * @param {*} configurator_view 
 */
const init_elements = function () {

    // ____________________

    data.forEach((params, key) => {

        // Prosegue solo se l'elemento corrente è pubblicabile
        if (!publishables_elements.includes(params.shirt_elements_type_id)) return;

        // Se l'elemento fa riferimento a un tessuto, salta
        if (params.shirt_elements_fabric_ref > 0) return;

        // Costruisce l'item
        Is[params.type] = make_item(params)

    });
}

// ______________________________________________________________________

/**
 * Costruisce un item
 */
const make_item = function (params) {

    // Crea un Item
    var I = Item.init(Object.assign(params, {
        image_url: IMAGE_URL,
        CRM_instance: CRM_instance,
        click: function () {
            select_element(elements_type[I.params.type], I.params.element_id);
            redraw();
        }
    }));

    // se l'elemento riceve l'evento select_me, viene selezionato
    I.element.addEventListener('select_me',() => {
        I.select();
    });

    // inizializza lo stato di selezione dell'item
    init_item_selection(I);

    return I;
}

// ______________________________________________________________________

const init_item_selection = function (I) {

    // Setta i selezionati nella configurazione generale (shirt_elements)
    if (I && I.params != undefined) {

        // Verifica se l'item non è un master
        if (!I.params.fabric_ref || I.params.fabric_ref == "0") {

            // L'elemento è già presente nella configurazione e il valore attuale è quello dell'elemento: lo seleziona
            if (elements[elements_type[I.params.type]] && elements[elements_type[I.params.type]] == I.params.element_id) {
                I.select();
            }

            // L'elemento non è presente nella configurazione, ma è indicato come valore default: lo seleziona
            if (!elements[elements_type[I.params.type]] && I.params.default == 1) {
                select_element(elements_type[I.params.type], I.params.element_id);
                I.select();
            }

        }
    }
}

// ______________________________________________________________________

/**
 * Renderizza l'immagine
 */
const redraw = function () {

    // encodedElements = base64(json(elements)): viene inviato come parametro unico per renderizzare l'immagine
    var JSONElements = JSON.stringify(elements);
    var encodedElements = btoa(JSONElements);
    encodedElements = encodedElements.replace("==", "").replace("=", "");

    // @todo: loading, interruzione, transizione sostituzione
    // Invoca l'immagine

    // event load -> loading
    $render_target.addEventListener("load", function () {

        // toglie l'immagine in stato di attesa
        $render_target.classList.remove('loading');

    });

    // effettua le operazioni quando redraw è stato invocato su una configurazione diversa rispetto alla precedente
    if (encodedElements != current_encoded_elements) {

        // pone l'immagine in stato di attesa
        $render_target.classList.add('loading');

        // cambia l'src all'immagine
        $render_target.setAttribute('src', CRM_BASE_URL + 'custom/manforti/shirt_draw/' + encodedElements);
        $render_target.setAttribute('data-src-original', CRM_BASE_URL + 'custom/manforti/shirt_draw/' + encodedElements + '/original');

        // allinea current_encoded_elements con la nuova configurazione
        current_encoded_elements = encodedElements;
    }

}

// ______________________________________________________________________