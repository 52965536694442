import axios from 'axios';
import LazyLoad from "vanilla-lazyload";
import Utils from '../../Utils.js';

import './module.scss';

var EventFabricOpen = document.createEvent('Event');
EventFabricOpen.initEvent('fabric_open', true, true);

var EventNewItem = document.createEvent('Event');
EventNewItem.initEvent('new_dropdown_item', true, true);

var EventClose = document.createEvent('Event');
EventClose.initEvent('close', true, true);

const EventDomChanged = new CustomEvent('dom-changed');

const API_SEARCH_ATTRIBUTES = '/search/shirt_attributes';

// ______________________________________________________________________

/**
 * LAZY LOAD
 */

// ______________________________________________________________________

class ModuleFeature {

    /**
     * Costruttore
     * @param {} settings 
     */
    constructor(settings) {

        // normalizza i parametri in ingresso
        this.params = this.init_params(settings);
        this.CRM_instance = settings.CRM_instance;
        this.selected = false;

        // 
        this.parentSelector = '.manf_single_element[data-element_type="' + this.params.type_id + '"] .row-manf_dropdown_item';
        var $single_element_container = document.querySelector(this.parentSelector);
        if ($single_element_container) {
            this.element = this.make_dropdown_item(this.params, $single_element_container);
        }

        // element: click
        var that = this;
        this.element.addEventListener('click', () => {

            // invia il select al parent via postmessage per tracciare il cambio di stato

            if (this.params.element_id != null) {
                parent.postMessage({
                    configurator: {
                        event: 'select_element',
                        configurator: {
                            element_id: this.params.element_id,
                            element_name: this.params.element_name || '',
                            element_price: this.params.price || 0,
                            element_type: this.params.type_id || ''
                        }
                    }
                }, "*");
            }

            // diffonde l'evento che è stato cliccato un elemento
            document.dispatchEvent(EventFabricOpen);
            that.select();
        });

        this.element.addEventListener('close', () => {
            this.unactive();
        });

        return this;
    }

    // __________________

    getSiblings() {

        var elem = this.element;

        // Setup siblings array and get the first sibling
        var siblings = [];
        var sibling = elem.parentNode.firstChild;

        // Loop through each sibling and push to the array
        while (sibling) {
            if (sibling.nodeType === 1 && sibling !== elem) {
                siblings.push(sibling);
            }
            sibling = sibling.nextSibling
        }

        return siblings;

    };

    closeSiblings() {
        var $siblings = this.getSiblings();

        $siblings.forEach(($sibling) => {
            if ($sibling.classList.contains('active')) {
                $sibling.dispatchEvent(EventClose); // invia l'evento chiusura solo a quelli aperti
            }
        });
    }

    // __________________

    /**
     * Esegue la callback al click
     */
    select() {

        this.params.selected = true;

        // Setta l'elemento come attivo
        this.active();

        // chiude tutti i siblings
        this.closeSiblings();

        // mostra la descrizione
        this.display_description();

        // stampa gli attributi
        this.display_attributes();

        // stampa i prezzi
        this.display_price();

        // gestisce eventuali eccezioni, come ad esempio se è slim allora non mostra il retro e azzra su liscio
        this.manage_exceptions();

        // Manage callback click
        // Se è stata passata una callback al click, la esegue
        if (this.params.click && typeof this.params.click == 'function') {
            this.params.click();
        }
    }

    // __________________

    manage_exceptions() {

        // eccezione
        if (this.params.type_id == "35") {
            let $back = Utils.querySelectorByAttribute("data-element_type", "8", "div");

            if (this.params.element_id == "2210") { // se l'elemento selezionato è slim -> allora nasconde "retro" e seleziona "liscio"
                $back.classList.add('d-none');

                // resetta il valore inviando un evento select_me all'item che deve essere selezionato
                let $back_s = Utils.querySelectorByAttributeAll('data-type', '8', 'div'); // aspetta valori multipli
                $back_s.forEach(($back_to_select) => {
                    // cerca elemento liscio e lo seleziona
                    let element_id = $back_to_select.getAttribute('data-element_id');
                    if (element_id == "37") {
                        let select_me = new Event('select_me');
                        $back_to_select.dispatchEvent(select_me);
                    }
                });
            } else if (this.params.element_id == "2209") { // se l'elemento selezionato è "regular" -> allora mostra "retro" e seleziona "pieghette"
                $back.classList.remove('d-none');

                // resetta il valore inviando un evento select_me all'item che deve essere selezionato
                let $back_s = Utils.querySelectorByAttributeAll('data-type', '8', 'div'); // aspetta valori multipli
                $back_s.forEach(($back_to_select) => {
                    // cerca elemento liscio e lo seleziona
                    let element_id = $back_to_select.getAttribute('data-element_id');
                    if (element_id == "38") {
                        let select_me = new Event('select_me');
                        $back_to_select.dispatchEvent(select_me);
                    }
                });

            }

            // comunica allo swiper del nodo interessato di aggiornare swiper
            let do_swipe_update = new Event('do_swipe_update');
            $back.dispatchEvent(do_swipe_update);
        }
    }

    // __________________

    /**
     * Ottiene gli attributi
     * @param {} params 
     * @returns 
     */
    retrieve_attributes(params) {

        return this.CRM_instance({
            method: 'post',
            url: API_SEARCH_ATTRIBUTES,
            data: Utils.objectToFormParameters(params)
        });
    }

    // __________________

    /**
     * Stampa gli attributi
     */
    display_price() {
        var $parent = this.element.closest('.manf_single_element');
        var $title = $parent.querySelector('.title');
        var $price = $title.querySelector('.price');

        // console.log($price);

        if ($price) {
            let price = this.params.price ? '+' + this.params.price + ' €' : '';
            $price.innerHTML = price;
        }
    }

    // __________________

    /**
     * Stampa gli attributi
     */
    display_attributes() {

        var that = this;

        // nodes
        var $parent = this.element.closest('.manf_single_element');
        var $container_description = $parent.querySelector('.container-description');
        var $attributes = $parent.querySelector('.attributes');

        let attributes_params = {
            offset: 0,
            orderby: 'shirt_elements_id',
            orderdir: 'desc',
            "where[shirt_elements_id]": this.params.element_id,
            maxdepth: 2
        };

        Promise.all([this.retrieve_attributes(attributes_params)]) // Promise per entrambe le chiamate
            .then(function (results) {

                // Raccoglie elements e types.
                // Nota: types contengono le intestazioni dal CRM
                var attributes = results[0].data.data;

                // clean
                $attributes.innerHTML = '';

                if (attributes.length > 0) {

                    attributes.forEach((attribute) => {

                        // crea l'elemento della lista
                        var $attribute = document.createElement('li');
                        $attribute.classList.add('list-group-item');
                        $attribute.classList.add('px-0');
                        $attribute.classList.add('py-1');
                        $attribute.classList.add('item');

                        // intestazioni (label e icon)
                        var header = that.get_attribute_header(attribute.shirt_attributes_type_value)

                        // label
                        var $label = document.createElement('strong');
                        $label.classList.add('d-block');
                        $label.classList.add('label');
                        $label.classList.add('ps-1');
                        $label.innerText = header.label;
                        $attribute.appendChild($label);

                        // icon
                        var $icon = document.createElement('i');
                        $icon.classList.add('material-icons');
                        $icon.classList.add('float-start');
                        $icon.classList.add('icon');
                        $icon.classList.add('mb-2');
                        $icon.innerText = header.icon;
                        $label.appendChild($icon);

                        // value
                        var $value = document.createElement('span');
                        $value.classList.add('value');
                        $value.innerText = t(attribute.shirt_attributes_value);
                        $attribute.appendChild($value);

                        // Appende l'elemento alla lista
                        $attributes.appendChild($attribute);
                    });

                }


            });
    }

    /**
     * Rainbow per le etichette degli attributi
     */
    get_attribute_header(shirt_attributes_type_value) {

        switch (shirt_attributes_type_value) {
            case 'color':
                return {
                    'label': t('Color'),
                    'icon': 'palette'
                };
            case 'season':
                return {
                    'label': t('Season'),
                    'icon': 'umbrella'
                };
            case 'weight':
                return {
                    'label': t('Weight'),
                    'icon': 'line_weight'
                };
            case 'features':
                return {
                    'label': t('Ironing'),
                    'icon': 'fingerprint'
                };
            case 'composition':
                return {
                    'label': t('Composition'),
                    'icon': 'texture'
                };
        }

        // Default empty
        return {
            'label': '',
            'icon': ''
        };

    }

    // __________________

    /**
     * Mostra la descrizione dell'elemento appena cliccato
     */
    display_description() {

        // description
        var $parent = this.element.closest('.manf_single_element');
        var $container_description = $parent.querySelector('.container-description');
        var $title = $container_description.querySelector('.title');
        var $shortDescription = $container_description.querySelector('.short-description');
        var $longDescription = $container_description.querySelector('.long-description');
        var $colDescription = $container_description.querySelector('.col-description');
        var $colImage = $container_description.querySelector('.col-image');

        // quali campi mostrare
        var fields = $parent.getAttribute('data-description-fields').split(',');

        let content_counter = 0;

        // appende il titolo
        if (fields.includes('title') && $title) {
            $title.innerHTML = t(this.params.element_name);
            content_counter++;
        } else {
            if ($title) $title.parentNode.removeChild($title);
        }

        // appende la short_description
        if (fields.includes('short-description') && $shortDescription) {
            content_counter++;
            $shortDescription.innerHTML = t(this.params.short_description);
        } else {
            if ($shortDescription) $shortDescription.parentNode.removeChild($shortDescription);
        }

        // appende la long_description
        if (fields.includes('long-description') && $longDescription) {
            content_counter++;
            $longDescription.innerHTML = t(this.params.long_description);
        } else {
            if ($longDescription) $longDescription.parentNode.removeChild($longDescription);
        }

        // Appende l'immagine
        if (fields.includes('image') && $colImage) {
            content_counter++;
            $colImage.style.backgroundImage = 'url(' + this.params.element_thumbnail + ')';
        } else {
            if ($colImage) $colImage.parentNode.removeChild($colImage);

            // Se l'immagine non è presente, allora modifica la dimensione della colonna descrizione.
            // @todo rifare con flex stretch
            $colDescription.classList.remove('col-8');
            $colDescription.classList.add('col-12');
        }

        // Display, se ci sono contenuti
        if (content_counter > 0) {
            $container_description.classList.add('show');
        }

    }

    // __________________

    init_params(settings) {

        // normalize
        settings.shirt_elements_default = settings.shirt_elements_default == "0" ? 0 : settings.shirt_elements_default;

        var params = {
            fabric_ref: settings.shirt_elements_fabric_ref,
            type: settings.shirt_elements_type,
            type_id: settings.shirt_elements_type_id,
            price: this.normalize_price(settings.shirt_elements_sell_price),
            element_id: settings.shirt_elements_id,
            default: Boolean(settings.shirt_elements_default),
            element_name: settings.shirt_elements_name,
            configurator_image: settings.image_url + settings.shirt_elements_configurator_image,
            main_picture: settings.image_url + settings.shirt_elements_main_picture,
            element_thumbnail: settings.image_url + settings.shirt_elements_thumbnail,
            long_description: settings.shirt_elements_long_description,
            short_description: settings.shirt_elements_short_description,
            selected: false,
            click: settings.click
        }

        return params;
    }

    // __________________

    /**
     * Normalizza il prezzo
     * @param {*} price 
     * @return string price
     */
    normalize_price(price) {
        return ~~parseFloat(price);
    }

    /**
     * Restituisce il valore del parametro
     * @param {*} name 
     */
    param(...theArgs) {

        if (theArgs.length == 1) {
            if (this[name]) return this[name]
            else return undefined;
        }

        if (theArgs.length == 2) {
            let name = arguments[1];
            this[name] = arguments[2]
        }

    }

    // __________________

    /**
     * Crea il template manf_dropdown_item
     * @param {} params 
     * @param {*} $container 
     */
    make_dropdown_item(params, $container) {

        // Item
        let $item = document.createElement('div');
        $item.classList.add('manf_dropdown_item');
        $item.classList.add('d-block');
        $item.classList.add('unselectable');
        $item.classList.add('swiper-slide');
        if (params.selected) $item.classList.add('active');
        // if(params.default) $item.classList.add('active');
        $item.setAttribute('data-type', params.type);
        $item.setAttribute('data-price', params.price);
        $item.setAttribute('data-element_id', params.element_id);
        $item.setAttribute('data-configurator_image', params.configurator_image);
        $item.setAttribute('data-main_picture', params.main_picture);
        $item.setAttribute('data-element_name', params.element_name);
        $item.setAttribute('data-thumbnail', params.element_thumbnail);

        // Thumbnail
        if (params.type == 1) {
            // Immagine di fabric
            $item.classList.add('lazy');
            $item.style.backgroundImage = 'url(' + params.main_picture + ')';
        } else {
            // Immagine per tutti i type (tranne fabric)
            let $item_thumb = document.createElement('img');
            $item_thumb.setAttribute('data-src', params.element_thumbnail);
            $item_thumb.setAttribute('data-lazy-function', "foo");
            $item_thumb.classList.add('img-fluid');
            $item_thumb.classList.add('unselectable');
            $item_thumb.classList.add('lazy');
            $item_thumb.classList.add('w-100');
            $item.appendChild($item_thumb);
        }

        // Item name
        let $item_name = document.createElement('div');
        $item_name.classList.add('mt-1');
        $item_name.classList.add('title');
        $item_name.innerText = t(params.element_name);
        $item.appendChild($item_name);

        // Item price
        let $item_price = document.createElement('div');
        $item_price.classList.add('mt-1');
        $item_price.classList.add('price');
        $item_price.innerText = params.price + " €";
        $item.appendChild($item_price);

        // posiziona il template alla fine del container (append)
        $container.appendChild($item);

        // Evento creazione nuovo nodo
        document.dispatchEvent(EventNewItem);

        document.body.dispatchEvent(EventDomChanged);

        return $item;
    }

    // __________________

    /**
     * Setta l'elemento come attivo
     */
    active() {
        this.element.classList.add('active');
    }

    // __________________

    /**
     * Setta l'elemento come disattivo
     */
    unactive() {
        if (this.element.classList.contains('active')) {
            this.element.classList.remove('active');
        }

    }

}

// _________________________

class Item {

    /**
     * Tutti gli Item
     */
    constructor() {

    }

    init(params) {
        this.Item = new ModuleFeature(params);

        return this.Item;
    }


}

// export
export default new Item();