"use strict";

const Utils = {

    // ______________________________________________________________________

    getBase64Image(img) {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");
    },

    // ______________________________________________________________________

    /**
     * Converte url in dataUrl
     * @param {} url 
     * @param {*} callback 
     */
    toDataUrl(url, callback) {
        var xhr = new XMLHttpRequest();

        xhr.onload = function () {

            var reader = new FileReader();

            reader.onloadend = function () {
                if (callback && reader.result) {
                    callback(reader.result);
                }
            }

            reader.readAsDataURL(xhr.response);
        };

        xhr.open('GET', url);
        xhr.responseType = 'blob';
        xhr.send();
    },

    // ______________________________________________________________________

    /**
     * Restituisce un parametro get by name
     * @param string
     */
    getParam( name ) {
        name = name.replace(/[\[]/,"\\\[").replace(/[\]]/,"\\\]");
        var regexS = "[\\?&]"+name+"=([^&#]*)";
        var regex = new RegExp( regexS );
        var results = regex.exec( window.location.href );
        if( results == null ) {
            return "";
        }
        else {
            return results[1];
        }
    },

    // ______________________________________________________________________

    /**
     * Trasforma un oggetto in form parameter
     * @param {*} params 
     * @return form parameters
     */
    objectToFormParameters(params) {
        var parameters = new URLSearchParams();

        for(let index in params) {
            parameters.append(index, params[index]);
        };

        return parameters;
    },

    // ______________________________________________________________________

    querySelectorByAttribute(attribute, value, element_type) {
        element_type = element_type || "*";
        var All = document.getElementsByTagName(element_type);
        for (var i = 0; i < All.length; i++)       {
            if (All[i].getAttribute(attribute) == value) { return All[i]; }
        }
    },

    // ______________________________________________________________________

    querySelectorByAttributeAll(attribute, value, element_type) {
        var results = [];
        element_type = element_type || "*";
        var All = document.getElementsByTagName(element_type);
        for (var i = 0; i < All.length; i++)       {
            if (All[i].getAttribute(attribute) == value) { results.push(All[i]); }
        }

        return results;
    }

};

export default Utils;
